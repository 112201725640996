/* eslint-disable no-mixed-operators */
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

const tileSize = 512;
const mercatorA = 6378137.0;
const projWorldSize = 512 / (6378137.0 * Math.PI) / 2;
const degToRad = Math.PI / 180;
const earthCirc = 40075000;
const defaultManager = new THREE.LoadingManager();

/**
 * Loader for loading gltf models
 * @param  {Function} manager
 */
export function gltfLoader(manager) {
  const loader = new GLTFLoader(manager || defaultManager);
  loader.setCrossOrigin('anonymous');
  const draco = new DRACOLoader();
  draco.setDecoderPath('../draco/');
  draco.setDecoderConfig({ type: 'js' });
  loader.setDRACOLoader(draco);
  return loader;
}

/**
 * Fires custom loaded event
 */
export function initManager() {
  defaultManager.onLoad = () => {
    document.dispatchEvent(
      new CustomEvent('models-loaded', {
        loaded: true, // could add data here
      })
    );
  };
}

/**
 * Scales and positions the model within the 3d world
 * @param  {Object3D} model
 * @param  {Vector3} scale
 * @param  {Array} coords
 */
export function addAtCoordinate(model, scale, coords) {
  model.matrixAutoUpdate = false;
  scale.multiplyScalar(projectedUnitsPerMeter(coords[1]));
  model.scale.copy(scale);
  model.position.copy(projectToWorld(coords));
  model.updateMatrix();
}

/**
 * @returns position within the 3D world
 * @param  {Array} coords
 */
export function projectToWorld(coords) {
  const projected = [
    -mercatorA * coords[0] * degToRad * projWorldSize,
    -mercatorA *
      Math.log(Math.tan(Math.PI * 0.25 + 0.5 * coords[1] * degToRad)) *
      projWorldSize,
  ];
  // elevation
  projected.push(coords[2] * projectedUnitsPerMeter(coords[1]) || 0);
  return new THREE.Vector3(projected[0], projected[1], projected[2]);
}

/**
 * @returns scale based on lat
 * @param  {Float} latitude
 */
export function projectedUnitsPerMeter(latitude) {
  return Math.abs(
    (tileSize * (1 / Math.cos((latitude * Math.PI) / 180))) / earthCirc
  );
}

/**
 * texture mapping object for gltf materials
 * @param {Object} model model obj
 */
export function getTextureOrderObj(model) {
  const { images, materials } = model.parser.json;

  // no textures on this model
  if (!images || !materials) {
    return {};
  }

  return materials.reduce((mapperObj, material, i) => {
    const textureIndex =
      material?.pbrMetallicRoughness?.baseColorTexture?.index || i;
    return {
      ...mapperObj,
      [material.name]: textureIndex,
    };
  }, {});
}

import React from 'react';

const Link = ({ handleClick, newTab, children, forwardRef, ...rest }) => {
  return (
    <a
      onClick={handleClick}
      target={newTab ? '_blank' : null}
      rel={newTab ? 'noopener noreferrer' : null}
      ref={forwardRef}
      {...rest}
    >
      {children}
    </a>
  );
};

export default Link;

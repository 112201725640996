import { useEffect, useState } from 'react';

const useKeyDown = () => {
  const [keys, setKeys] = useState([]);
  useEffect(() => {
    const duplicateModel = (e) => {
      if (!keys.includes(e.keyCode)) {
        setKeys([...keys, e.keyCode]);
      }
    };

    const clearKeys = () => {
      setKeys([]);
    };

    window.addEventListener('keydown', duplicateModel);
    window.addEventListener('keyup', clearKeys);

    return () => {
      window.removeEventListener('keydown', duplicateModel);
      window.removeEventListener('keyup', clearKeys);
    };
  }, [keys]);

  return keys;
};

export default useKeyDown;

const initialState = { fetchError: {} };
const config = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'ERROR_MESSAGE': {
      return { ...state, fetchError: action.message };
    }
    default:
      return state;
  }
};

export default config;

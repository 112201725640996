import React from 'react';
import cx from 'classnames';
import ReactToolTip from 'react-tooltip';
import './Button.scss';

const Button = ({
  toolTipId,
  children,
  className,
  handleClick,
  active,
  myRef,
  renderToolTip,
  toolTipOptions,
  ...rest
}) => (
  <>
    <button
      className={cx(className, { active })}
      onClick={handleClick}
      ref={myRef}
      data-tip
      data-for={toolTipId}
      {...rest}
    >
      {children}
    </button>
    {renderToolTip && (
      <ReactToolTip id={toolTipId} effect="solid" {...toolTipOptions}>
        {renderToolTip()}
      </ReactToolTip>
    )}
  </>
);

export default Button;

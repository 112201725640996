import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const useGetUser = () => {
  const { keycloak } = useKeycloak();
  const [user, setUser] = useState({});
  useEffect(() => {
    const getUser = () => {
      const { family_name, given_name, name, email } = keycloak.tokenParsed;
      setUser({ family_name, given_name, name, email });
    };
    getUser();
  }, [keycloak]);

  return user;
};

export default useGetUser;

/* eslint-disable no-undef */
import * as THREE from 'three';
import { matrixSync } from './cameraSync';
import { initBuildings } from './buildings';
import { initLights } from './lights';
import { initManager } from './model';
import { getMap } from './map';

const scene = new THREE.Scene();
const world = new THREE.Group();
let camera;
let renderer;
let map;
let previewRenderer;

/**
 * Initializes threejs groups
 */
export function init() {
  initManager();
  initLights(scene);
  initBuildings(world);
  map = getMap();
  camera = new THREE.PerspectiveCamera(
    40,
    map.transform.width / map.transform.height,
    1,
    5000000000
  );
  scene.add(world);
}

/**
 * @returns the 3D layer for inserting into MapboxGL
 */
export function createCustomLayer(options) {
  return {
    id: 'scene',
    type: 'custom',
    renderingMode: '3d',
    onAdd: (map, gl) => {
      renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
        canvas: map.getCanvas(),
        context: gl,
      });
      // clip at the ground level
      if (options.clip) {
        const clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 1);
        renderer.clippingPlanes = [clippingPlane];
      }
      // settings optimized for gltf
      renderer.autoClear = false;
      renderer.outputEncoding = 3001;
      renderer.gammaFactor = 2.2;
    },
    render: (gl) => {
      renderer.state.reset();
      matrixSync(map, camera, world);
      renderer.render(scene, camera);
      map.triggerRepaint();
    },
  };
}

/**
 * Gets the layer position for where to add the custom 3d layer
 */
export function getPlacementLayer() {
  const { layers } = map.getStyle();
  const lastRasterIndex = layers
    .map((layer) => layer.type)
    .lastIndexOf('raster');
  if (lastRasterIndex !== -1 && lastRasterIndex + 1 < layers.length) {
    return layers[lastRasterIndex + 1].id;
  }
  return null;
}

export function getPreviewRenderer() {
  previewRenderer =
    previewRenderer ||
    new THREE.WebGLRenderer({
      // this is needed for grabbing thumbnails
      preserveDrawingBuffer: true,
    });
  return previewRenderer;
}

export function getScene() {
  return scene;
}

export function getWorld() {
  return world;
}

export function getCamera() {
  return camera;
}

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOverlays } from '../../../actions/OverlayActions.redux';
import { getMap, addTileLayer } from '../../interface/';

const useRenderings = ({ isMapReady = false }) => {
  const [firstLoadComplete, setFirstLoadComplete] = useState(false);
  const {
    overlays,
    config: { map },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    isMapReady &&
      getMap().on('style.load', () => {
        dispatch(fetchOverlays(map));
      });
  }, [dispatch, map, isMapReady]);

  useEffect(() => {
    if (!firstLoadComplete && overlays.length > 0) {
      overlays.forEach((overlay) => {
        addTileLayer(overlay);
      });
      setFirstLoadComplete(true);
    }
  }, [overlays, firstLoadComplete]);

  return null;
};

export default useRenderings;

import axios from 'axios';

const _360api = process.env.REACT_APP_360_API;
const atlasApi = process.env.REACT_APP_ATLAS_API;
const atlasKey = process.env.REACT_APP_ATLAS_KEY;

const api = {
  getSettings(mapId) {
    return axios.get(`${_360api}/${mapId}/`, { withCredentials: true });
  },

  updateSettings(mapId, data) {
    return axios.put(`${_360api}/${mapId}/`, data, { withCredentials: true });
  },

  createSettings(mapId, data) {
    return axios.post(`${_360api}/${mapId}/`, data, { withCredentials: true });
  },

  deleteSettings(mapId, data) {
    return axios.delete(`${_360api}/${mapId}/`, data, {
      withCredentials: true,
    });
  },

  getPlaces(mapId) {
    return axios.get(`${_360api}/${mapId}/locations`, {
      withCredentials: true,
    });
  },

  getPlaceById(mapId, id) {
    return axios.get(`${_360api}/${mapId}/locations/${id}`, {
      withCredentials: true,
    });
  },

  postPlace(mapId, data) {
    return axios.post(`${_360api}/${mapId}/locations`, data, {
      withCredentials: true,
    });
  },

  updatePlace(mapId, id, data) {
    return axios.put(`${_360api}/${mapId}/locations/${id}`, data, {
      withCredentials: true,
    });
  },

  deletePlace(mapId, id) {
    return axios.delete(`${_360api}/${mapId}/locations/${id}`, {
      withCredentials: true,
    });
  },

  uploadModel(data, mapId) {
    return axios.post(`${_360api}/${mapId}/models`, data, {
      withCredentials: true,
    });
  },

  updateExistingModel(data, mapId, id) {
    return axios.post(`${_360api}/${mapId}/models/${id}`, data, {
      withCredentials: true,
    });
  },

  deleteModel(mapId, id) {
    return axios.delete(`${_360api}/${mapId}/models/${id}`, {
      withCredentials: true,
    });
  },

  uploadThumbnail(data, mapId, id) {
    return axios.post(`${_360api}/${mapId}/models/${id}/thumbnail`, data, {
      withCredentials: true,
    });
  },

  uploadTextureLod(data, mapId, id) {
    return axios.post(`${_360api}/${mapId}/models/${id}/textures`, data, {
      withCredentials: true,
    });
  },

  deleteTextureLod(fileName, mapId, id) {
    return axios.delete(
      `${_360api}/${mapId}/models/${id}/textures/${fileName}`,
      { withCredentials: true }
    );
  },

  getActiveModels(mapId) {
    return axios.get(`${_360api}/${mapId}/models/?attributes=true`, {
      withCredentials: true,
    });
  },

  getModelAttributes(mapId, id) {
    return axios.get(`${_360api}/${mapId}/models/${id}`, {
      withCredentials: true,
    });
  },

  updateModelAttributes(mapId, id, data) {
    return axios.put(`${_360api}/${mapId}/models/${id}`, data, {
      withCredentials: true,
    });
  },

  getFile(mapId, id, fileName) {
    return axios.get(`${_360api}/${mapId}/models/${id}/model/${fileName}`, {
      responseType: 'blob',
      withCredentials: true,
    });
  },

  getLights(mapId) {
    return axios.get(`${_360api}/${mapId}/lights`, { withCredentials: true });
  },

  createLight(mapId, data) {
    return axios.post(`${_360api}/${mapId}/lights`, data, {
      withCredentials: true,
    });
  },

  updateLight(mapId, id, data) {
    return axios.put(`${_360api}/${mapId}/lights/${id}`, data, {
      withCredentials: true,
    });
  },

  deleteLight(mapId, id) {
    return axios.delete(`${_360api}/${mapId}/lights/${id}`, {
      withCredentials: true,
    });
  },

  // -------------------------------------------------------------------------
  // atlasbe-api-requests

  getConfig(mapId) {
    return axios.get(`${atlasApi}/config?map=${mapId}&key=${atlasKey}`);
  },

  getAtlasLocations(mapId) {
    return axios.get(`${atlasApi}/locations?map=${mapId}&key=${atlasKey}`);
  },

  getOverlays(mapId) {
    return axios.get(`${atlasApi}/overlays?map=${mapId}&key=${atlasKey}`);
  },
};

export default api;

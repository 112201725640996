import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useFetchMapId, useInterceptor } from './common/hooks';
import Routes from './Routes';
import Header from './Header/Header';
import AuthPage from './Pages/AuthPage/AuthPage';

const App = () => {
  const { keycloak, initialized } = useKeycloak();
  if (initialized && !keycloak.authenticated) {
    keycloak.login();
  }
  // only fetch when authenticated
  useInterceptor(keycloak.token);
  useFetchMapId(keycloak.authenticated);

  if (!!keycloak.authenticated) {
    return (
      <>
        <Header />
        <Routes />
      </>
    );
  }

  return <AuthPage />;
};

export default App;

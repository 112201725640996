import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import './Button.scss';
import Icon from '../layout/Icon';

const ButtonUpdate = (props) => {
  const {
    changed,
    changedText,
    className,
    disabled,
    doneIcon,
    doneText,
    handleClick,
    name,
    saving,
    savingText,
    style,
    ...rest
  } = props;
  const renderStatus = () => {
    if (saving) {
      return (
        <React.Fragment>
          <ClipLoader key="loader" color={'none'} size={10} />
          {savingText}
        </React.Fragment>
      );
    } else if (changed) {
      return (
        <React.Fragment>
          <Icon iconName="sync" />
          {changedText}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {doneIcon && <Icon iconName={doneIcon} />}
        {doneText}
      </React.Fragment>
    );
  };
  return (
    <button
      className={`btn-update ${className}`}
      name={name}
      style={style}
      onClick={handleClick}
      disabled={disabled}
      {...rest}
    >
      {renderStatus()}
    </button>
  );
};

ButtonUpdate.propTypes = {
  changed: PropTypes.bool,
  changedText: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  doneIcon: PropTypes.string,
  doneText: PropTypes.string,
  handleClick: PropTypes.func,
  name: PropTypes.string,
  saving: PropTypes.bool,
  savingText: PropTypes.string,
  style: PropTypes.object,
};

export default ButtonUpdate;

import React, { useEffect, lazy, Suspense } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { usePrevious } from './common/hooks';

import { ClipLoader } from 'react-spinners';

const NotFound = lazy(() => import('./Pages/ErrorPages/404.react'));
const Sidebar = lazy(() => import('./Sidebar/Sidebar'));
const Mapbox = lazy(() => import('./Map/Map'));
const LocationEditor = lazy(() => import('./Place/PlaceEditor/PlaceEditor'));
const LocationWrapper = lazy(() => import('./Place/PlaceWrapper'));
const ModelUpload = lazy(() =>
  import('./ModelLibrary/ModelUpload/ModelUpload')
);
const ModelLibrary = lazy(() => import('./ModelLibrary/ModelLibrary'));
const Model = lazy(() => import('./ModelLibrary/Model/Model'));
const Settings = lazy(() => import('./Settings/Settings'));
const Lights = lazy(() => import('./Light/Lights'));
const LightEditor = lazy(() => import('./Light/LightEditor/LightEditor'));

const Routes = () => {
  const location = useLocation();
  const history = useHistory();
  const lastLocation = usePrevious(location);

  useEffect(() => {
    // keycloak redirect messes with location on init load
    // eslint-disable-next-line no-unused-vars
    const [beforeHash, hash] = window.location.hash.split('#');
    if (location.pathname !== hash) {
      history.replace(hash);
    }
  }, [history, location.pathname]);
  return (
    <div>
      <Suspense fallback={<ClipLoader />}>
        <Route path="/">
          <Sidebar>
            <Route exact path={'/places'} component={LocationWrapper} />
            <Route
              exact
              path={'/places/model/:id'}
              component={LocationWrapper}
            />
            <Route
              path="/new-place"
              render={() => <LocationEditor lastLocation={lastLocation} />}
            />
            <Route
              path="/place/:id"
              render={() => <LocationEditor lastLocation={lastLocation} />}
            />
            <Route path="/models" component={ModelLibrary} />
            <Route
              path="/model/:id"
              render={() => <Model lastLocation={lastLocation} />}
            />
            <Route
              exact
              path="/upload"
              render={() => <ModelUpload lastLocation={lastLocation} />}
            />
            <Route exact path="/upload/:id" component={ModelUpload} />
            <Route
              path="/settings"
              render={() => <Settings currentSettings={{ minZoom: 17 }} />}
            />
            <Route path="/lights" component={Lights} />
            <Route exact path="/light" component={LightEditor} />
            <Route exact path="/light/:id" component={LightEditor} />
          </Sidebar>
        </Route>
        <Route path="/" component={Mapbox} />
        <Route exact path="/notfound" component={NotFound} />
      </Suspense>
    </div>
  );
};

export default Routes;

import api from '../api/api';

export const getLights = () => async (dispatch, getState) => {
  const { map } = getState().config;
  try {
    const res = await api.getLights(map);
    if (res.data.length === 0) {
      dispatch(
        createLight({
          light_type: 'hemisphere',
          light_name: 'Default Light',
          light_intensity: 1.5,
          light_position: '',
        })
      );
    }
    res.data.forEach((light) => {
      dispatch(saveLight(light.id, light));
    });
  } catch (error) {
    console.log(error.response);
  }
};

export const createLight = (light, callback) => async (dispatch, getState) => {
  const { map } = getState().config;
  try {
    const res = await api.createLight(map, light);
    if (res.status === 200) {
      dispatch(getLights());
      callback && callback();
    }
  } catch (error) {
    console.log(error.response);
  }
};

export const updateLight = (id, light) => async (dispatch, getState) => {
  const { map } = getState().config;
  try {
    const res = await api.updateLight(map, id, light);
    res.status === 200 && dispatch(saveLight(id, light));
  } catch (error) {
    console.log(error.response);
  }
};

export const deleteLight = (id) => async (dispatch, getState) => {
  const { map } = getState().config;
  try {
    const res = await api.deleteLight(map, id);
    res.status === 200 && dispatch(deleteLightFromStore(id));
  } catch (error) {
    console.log(error.response);
  }
};

/**
 * saves light object
 * @param  {uuid} id - light id
 * @param  {object} data - light object
 */
export const saveLight = (id, data) => ({
  type: 'SAVE_LIGHT',
  id,
  data,
});

/**
 * deletes light object
 * @param  {uuid} id - light id
 */
export const deleteLightFromStore = (id) => ({
  type: 'DELETE_LIGHT',
  id,
});

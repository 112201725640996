import * as THREE from 'three';

/**
 * Syncs the threejs camera with mapbox gl camera
 * @param  {Object} map
 * @param  {Object} camera
 * @param  {Object} world
 */
export function matrixSync(map, camera, world) {
  const fov = 0.6435011087932844;
  const halfFov = fov / 2;
  const { transform } = map;
  const zoomScale = transform.scale;
  const cameraTocoordsDistance = (0.5 / Math.tan(halfFov)) * transform.height;
  const halfTileSize = 256;
  const groundAngle = Math.PI / 2 + transform._pitch;
  const topHalfSurfaceDistance =
    (Math.sin(halfFov) * cameraTocoordsDistance) /
    Math.sin(Math.PI - groundAngle - halfFov);
  const farZ =
    (Math.cos(Math.PI / 2 - transform._pitch) * topHalfSurfaceDistance +
      cameraTocoordsDistance) *
    1.01;
  const x = transform.x || transform.point.x;
  const y = transform.y || transform.point.y;
  camera.matrixAutoUpdate = false;
  world.matrixAutoUpdate = false;
  camera.projectionMatrix = makePerspectiveMatrix(
    fov,
    transform.width / transform.height,
    1,
    farZ
  );
  camera.matrixWorld.copy(
    new THREE.Matrix4()
      .premultiply(
        new THREE.Matrix4().makeTranslation(0, 0, cameraTocoordsDistance)
      )
      .premultiply(new THREE.Matrix4().makeRotationX(transform._pitch))
      .premultiply(new THREE.Matrix4().makeRotationZ(transform.angle))
  );
  world.matrix = new THREE.Matrix4();
  world.matrix
    .premultiply(new THREE.Matrix4().makeRotationZ(Math.PI))
    .premultiply(
      new THREE.Matrix4().setPosition(
        new THREE.Vector3(halfTileSize, -halfTileSize, 0)
      )
    )
    .premultiply(new THREE.Matrix4().makeScale(zoomScale, zoomScale, zoomScale))
    .premultiply(new THREE.Matrix4().setPosition(new THREE.Vector3(-x, y, 0)));
}
/**
 * @returns matrix for the camera
 * @param  {Float} fovy
 * @param  {Integer} aspect
 * @param  {Integer} near
 * @param  {Float} far
 */
function makePerspectiveMatrix(fovy, aspect, near, far) {
  const out = new THREE.Matrix4();
  const f = 1.0 / Math.tan(fovy / 2);
  const nf = 1 / (near - far);
  const newMatrix = [
    f / aspect,
    0,
    0,
    0,
    0,
    f,
    0,
    0,
    0,
    0,
    (far + near) * nf,
    -1,
    0,
    0,
    2 * far * near * nf,
    0,
  ];
  out.elements = newMatrix;
  return out;
}

import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import clickOutsideHandler from '../../../utils/clickOutsideHandler';
import './Dropdown.scss';

const Dropdown = (props) => {
  const { dropDownType, rootAdmin, children, close } = props;
  const dropDownRef = useRef();
  useEffect(() => {
    const handleOutsideClick = (e) => {
      clickOutsideHandler({
        eventTarget: e.target,
        elementArray: [dropDownRef.current],
        toggleCallback: close,
      });
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [close]);

  const renderOptions = () =>
    children.map((option, i) => {
      if (!option) return null;
      const classes = classnames({
        'dropdown-option': true,
        last: i === children.length - 1,
      });

      return (
        <li key={i} className={classes} onClick={close}>
          {option}
        </li>
      );
    });

  return (
    <React.Fragment>
      <ul
        className={`dropdown-list ${rootAdmin ? 'root-' : ''}${dropDownType}`}
        ref={dropDownRef}
      >
        {renderOptions()}
      </ul>
    </React.Fragment>
  );
};

export default Dropdown;

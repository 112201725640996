import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConfig } from '../../../actions/ConfigActions.redux';
import { fetchSettings } from '../../../actions/SettingsActions.redux';
import { fetchPlaces } from '../../../actions/PlaceActions.redux';

import { getLights } from '../../../actions/LightActions.redux';

const useFetchMapId = (authorized) => {
  const map = useSelector((state) => state.config.map);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const mapId = new URL(document.location).searchParams.get('id');
    authorized && !map && dispatch(fetchConfig(mapId, history));
    if (map) {
      dispatch(fetchSettings());
      dispatch(getLights());
      dispatch(fetchPlaces(map));
    }
  }, [map, history, dispatch, authorized]);
};

export default useFetchMapId;

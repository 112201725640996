import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const _sources = {};
const _layers = {};

let map = null;
let defaultStyle = null;

export function initMap({ container, style, lngLat, zoom, pitch }) {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
  defaultStyle = style
    ? `mapbox://styles/concept3d/${style}?optimize=true`
    : `${process.env.REACT_APP_TILE_SOURCE}${process.env.REACT_APP_TILE_STYLE}/style.json`;
  map = new mapboxgl.Map({
    container,
    style: defaultStyle,
    hash: false,
    center: lngLat,
    zoom,
    pitch: pitch || 60,
  });
}

export function getMap() {
  return map;
}

export function getStyle() {
  return defaultStyle;
}

export function setStyle(style) {
  map.setStyle(style);
}

export function addTileLayer(data) {
  const url = `${process.env.REACT_APP_ASSETS}/assets/${data.map}/${data.url}/{z}/{x}/{y}`;
  const id = data.id.toString();
  // overlay source
  _sources[id] = {
    type: 'raster',
    tileSize: 256,
    scheme: 'tms',
    tiles: [url],
    maxzoom: 22,
  };

  if (!map.getSource(id)) {
    map.addSource(id, _sources[id]);
  }

  // overlay layer
  _layers[id] = {
    id,
    type: 'raster',
    source: id,
    'source-layer': id,
    layout: {
      visibility: data.level === 0 ? 'visible' : 'none',
    },
  };
  if (!getTileLayer(id)) {
    map.addLayer(_layers[id], 'scene');
  }
}

export function toggleTileLayer(id) {
  const layer = getTileLayer(id);
  if (!layer) return false;
  const notVisible = layer.visibility === 'none';
  map.setLayoutProperty(
    id.toString(),
    'visibility',
    notVisible ? 'visible' : 'none'
  );
  return notVisible;
}

export function getTileLayer(id) {
  return map.getLayer(id);
}
// --------------------------------------------------------------------------
// Marker Actions
export function createMarker(className, callBack, draggable) {
  const markerDiv = document.createElement('div');
  markerDiv.className = `marker ${className}`;
  callBack && markerDiv.addEventListener('click', () => callBack());
  const marker = new mapboxgl.Marker(markerDiv, {
    draggable: draggable || false,
  });
  return marker;
}
// --------------------------------------------------------------------------
// Map Actions
export function resizeMap() {
  let count = 0;
  const interval = setInterval(() => {
    map.resize();
    if (count >= 1000) {
      clearInterval(interval);
    }
    count += 10;
  }, 10);
}

export function panTo(latLng, zoom, pitch) {
  map.panTo(latLng, {
    zoom: zoom || map.getZoom(),
    pitch: pitch || map.getPitch(),
  });
}

export function rotateTo({ lat, lng, zoom, pitch, bearing }) {
  map.rotateTo(bearing, { zoom, pitch, center: [lng, lat] });
}

export function zoomTo(zoom) {
  map.zoomTo(zoom);
}

// --------------------------------------------------------------------------
// Utils
export function getPlacementLayer() {
  const { layers } = map.getStyle();
  const lastRasterIndex = layers
    .map((layer) => layer.type)
    .lastIndexOf('raster');
  if (lastRasterIndex !== -1 && lastRasterIndex + 1 < layers.length) {
    return layers[lastRasterIndex + 1].id;
  }
  return null;
}

export function contains(lat, lng, _bounds) {
  const bounds = _bounds || map.getBounds();
  const ne = toLatLng(bounds.getNorthEast());
  const sw = toLatLng(bounds.getSouthWest());
  return lat >= sw[0] && lat <= ne[0] && lng >= sw[1] && lng <= ne[1];
}

function toLatLng(data) {
  if (data[0]) {
    return [data[1], data[0]];
  }
  return [data.lat, data.lng];
}

export function getCenter() {
  return map.getCenter();
}

import React from 'react';
import PropTypes from 'prop-types';
import './Icon.scss';

const Icon = ({ iconName, ...rest }) =>
  iconName ? (
    <i className="material-icons" aria-hidden="true" {...rest}>
      {iconName}
    </i>
  ) : null;

Icon.propTypes = {
  iconName: PropTypes.string,
};

export default Icon;

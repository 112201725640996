import { useEffect, useRef } from 'react';

const useSetFocus = () => {
  const ref = useRef();
  useEffect(() => {
    const focusRef = ref && ref.current;
    focusRef && focusRef.focus();
  }, [ref]);

  return ref;
};

export default useSetFocus;

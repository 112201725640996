import * as THREE from 'three';
import { addAtCoordinate } from './model';

let buildings = null;

export function initBuildings(world) {
  buildings = new THREE.Group();
  world.add(buildings);
}

export function getBuildings() {
  return buildings;
}

/**
 * @returns a specific model by UUID from the buildings group
 * @param  {uuid} uuid
 */
export function getModelById(uuid) {
  return buildings?.children.find((model) => model.uuid === uuid);
}

/**
 * Adds a model to the building group and positions it
 * @param  {Object3D} model
 * @param  {Vector3} scale
 * @param  {Array} location
 */
export function addBuilding(model, scale, location) {
  buildings.add(model);
  if (scale) {
    addAtCoordinate(model, scale, location);
  }
}

/**
 * Removes a model from the buildings group
 * @param  {Object3D} model
 */
export function removeBuilding(model) {
  return buildings.remove(model);
}

/**
 * Toggles the buildings group on or off
 * @param  {Boolean} boolean
 */
export function toggleBuildings(boolean) {
  buildings.children.forEach((model) => (model.visible = boolean));
}

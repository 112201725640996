import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useRouteBlocker = ({
  hasEdits,
  setShowModal,
  showModal,
  cancel,
  setCancel,
}) => {
  const [targetPath, setTargetPath] = useState('');
  const history = useHistory();

  useEffect(() => {
    const unblock = history.block(({ pathname, state = {} }) => {
      if (!state.byPassRouteBlocker && hasEdits && !showModal) {
        setTargetPath(pathname);
        setShowModal(true);
        return false;
      }
    });
    if (cancel) {
      history.push(targetPath);
      setShowModal(false);
      setCancel(false);
    }
    return () => {
      typeof unblock === 'function' && unblock();
    };
  }, [
    cancel,
    setCancel,
    hasEdits,
    targetPath,
    history,
    showModal,
    setShowModal,
  ]);
};

export default useRouteBlocker;

import api from '../api/api';

/**
 * Fetches all places
 * @param  {number} mapId - id from search params
 */
export const fetchPlaces = (mapId) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await api.getPlaces(mapId);
      data.forEach((place) => {
        dispatch(savePlace({ ...place, textures: JSON.parse(place.textures) }));
      });
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Fetch a single place
 * @param  {string} id - place id
 */
export const fetchPlace = (id) => {
  return async (dispatch, getState) => {
    const { map } = getState().config;
    try {
      const { data } = await api.getPlaceById(map, id);
      const [place] = data;
      dispatch(savePlace({ ...place, textures: JSON.parse(place.textures) }));
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Posts a new place
 * @param  {object} data - new place data
 */
export const postPlace = (data, callback) => {
  return async (dispatch, getState) => {
    const { map } = getState().config;
    dispatch(placeSaving());
    try {
      const {
        data: { id },
      } = await api.postPlace(map, data);
      dispatch(fetchPlace(id));
      typeof callback === 'function' && callback(id);
      dispatch(placeSaving());
    } catch (error) {
      console.log(error);
      dispatch(placeSaving());
    }
  };
};

/**
 * Updates a places attributes
 * @param  {string} id - the model's id
 * @param  {object} data - the model's attributes
 */
export const updatePlace = (id, data) => {
  return async (dispatch, getState) => {
    const { map } = getState().config;
    dispatch(placeSaving());
    try {
      const res = await api.updatePlace(map, id, data);
      res.data && dispatch(savePlace({ ...data, id }));
      dispatch(placeSaving());
    } catch (error) {
      console.log(error.message);
      dispatch(placeSaving());
    }
  };
};

/**
 * Deletes a places attributes
 * @param  {string} id - the model's id
 * @param  {object} data - the model's attributes
 */
export const deletePlace = (id) => {
  return async (dispatch, getState) => {
    const {
      places: { places },
      config: { map },
    } = getState();
    const modelId = places[id].model_id;
    try {
      const res = await api.deletePlace(map, id);
      res.status === 200 && dispatch(deletePlaceById(id, modelId));
    } catch (error) {
      console.log(error.message);
    }
  };
};

/**
 * fetches place based on mapId
 */
export const fetchAtlasLocations = () => async (dispatch, getState) => {
  const { map } = getState().config;
  try {
    const res = await api.getAtlasLocations(map);
    dispatch(saveAtlasLocations(res.data));
  } catch (error) {
    console.log(error.response);
  }
};

/**
 * toggles saving state
 * @param  {array} data - map object
 */
export const placeSaving = () => ({
  type: 'PLACE_SAVING',
});

/**
 * saves place
 * @param  {array} data - map object
 */
export const savePlace = (data) => ({
  type: 'SAVE_PLACE',
  data,
});

/**
 * deletes a place
 * @param  {string} id - model id
 */
export const deletePlaceById = (id, modelId) => {
  return {
    type: 'DELETE_PLACE',
    id,
    modelId,
  };
};

/**
 * saves atlas places array
 * @param  {array} data - map object
 */
export const saveAtlasLocations = (data) => ({
  type: 'SAVE_ATLAS_LOCATIONS',
  data,
});

import { omit } from 'lodash';

const initialState = {};

const lights = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_LIGHT': {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...action.data,
        },
      };
    }
    case 'DELETE_LIGHT': {
      return omit(state, action.id);
    }
    default:
      return state;
  }
};

export default lights;

import * as THREE from 'three';

/**
 * Convertes deg to a radian
 * @param  {Integer} num
 * @returns Radian
 */
export function getRad(num) {
  return THREE.Math.degToRad(num);
}
/**
 * Creates a vector 3
 * @param  {Integer} num
 * @returns Vector3
 */
export function getVector3(num) {
  return new THREE.Vector3(num, num, num);
}

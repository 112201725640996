import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetUser } from '../../common/hooks';
import Dropdown from '../../common/dropdown/Dropdown';
import SignOut from './SignOut';
import { Link, Button } from '../../common/buttons';
import Icon from '../../common/layout/Icon';

const UserMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { map: mapId } = useSelector((state) => state.config);
  const userInfo = useGetUser();
  const location = useLocation();
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const cmsUrl = process.env.REACT_APP_CMS_URL;
  const feUrl = process.env.REACT_APP_FE_URL;

  return (
    <div className="profile-wrapper">
      <Button
        tabIndex={0}
        className="user-profile"
        handleClick={toggleMenu}
        aria-expanded={showMenu}
      >
        <span data-name="userMenu">
          {userInfo.name}
          <Icon data-name="userMenu" iconName="arrow_drop_down" />
        </span>
      </Button>
      {showMenu && (
        <Dropdown
          rootAdmin
          path={location.pathname}
          dropDownType={'accounts'}
          close={toggleMenu}
        >
          <Link href={`${feUrl}?id=${mapId}`} newTab>
            Go To Map
          </Link>
          <Link href={`${cmsUrl}/?id=${mapId}`} newTab>
            Go To CMS
          </Link>
          <SignOut />
        </Dropdown>
      )}
    </div>
  );
};

export default UserMenu;

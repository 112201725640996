import api from '../api/api';

/**
 * fetches config set by cms
 * @param  {number} mapId - maps id
 */
export const fetchConfig = (mapId, history) => async (dispatch) => {
  try {
    const { data } = await api.getConfig(mapId);
    if (!data.map3d) {
      throw new Error('Maps3d not enabled');
    }
    dispatch(saveConfigData(data));
  } catch (error) {
    history.push('/notfound');
  }
};

/**
 * saves the config object
 * @param  {object} data - config data
 */
export const saveConfigData = (data) => ({
  type: 'SAVE_CONFIG',
  data,
});

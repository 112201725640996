import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../buttons/Button';
import './DialogModal.scss';

class DialogModal extends Component {
  render() {
    const {
      children,
      styles,
      className,
      closeModal,
      isOpen,
      shouldCloseOnOverlayClick,
      ariaAttributes,
      id,
    } = this.props;
    const hasAriaAttributes =
      typeof ariaAttributes === 'object' &&
      Object.keys(ariaAttributes).length > 0;
    const hasId = id || '';
    return (
      <Modal
        isOpen={isOpen}
        style={styles}
        className={className ? `dialog-modal ${className}` : 'dialog-modal'}
        overlayClassName={
          className
            ? `dialog-modal-overlay ${className}`
            : 'dialog-modal-overlay'
        }
        id={hasId}
        onRequestClose={closeModal}
        closeTimeoutMS={500}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        aria={hasAriaAttributes ? ariaAttributes : {}}
        appElement={document.querySelector('#root')}
      >
        {shouldCloseOnOverlayClick && (
          <Button
            handleClick={closeModal}
            className="dialog-modal-close-btn"
            aria-label="close modal"
          >
            ×
          </Button>
        )}
        {children}
      </Modal>
    );
  }
}

DialogModal.propTypes = {
  children: PropTypes.object,
  styles: PropTypes.object,
  className: PropTypes.string,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  ariaAttributes: PropTypes.object,
  id: PropTypes.string,
};

export default DialogModal;

import { omit } from 'lodash';

const initialState = {
  activeModels: {},
  inactiveModels: {},
  isUploading: false,
  isUploadingTexture: false,
  isDeletingTexture: false,
  isSaving: false,
  thumbBlobs: {},
  modelBlobs: {},
  textureOrder: {},
};

const models = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SAVE_MODEL': {
      return {
        ...state,
        activeModels: {
          ...state.activeModels,
          [action.id]: {
            ...state.activeModels[action.id],
            ...action.data,
          },
        },
      };
    }
    case 'SAVE_INACTIVE_MODEL': {
      return {
        ...state,
        activeModels: {
          ...state.activeModels,
          [action.id]: {
            ...state.activeModels[action.id],
            ...action.data,
          },
        },
      };
    }
    case 'DELETE_MODEL': {
      return {
        ...state,
        activeModels: omit(state.activeModels, action.id),
        inactiveModels: {
          ...state.inactiveModels,
          [action.id]: {
            ...state.inactiveModels[action.id],
            ...state.activeModels[action.id],
          },
        },
      };
    }
    case 'SAVE_BLOB': {
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          [action.id]: action.blob,
        },
      };
    }
    case 'UPDATE_TEXTURES': {
      const stateTextures = state.activeModels[action.id].textures || [];
      return {
        ...state,
        activeModels: {
          ...state.activeModels,
          [action.id]: {
            ...state.activeModels[action.id],
            textures: [...stateTextures, ...action.data],
          },
        },
      };
    }
    case 'DELETE_TEXTURE': {
      return {
        ...state,
        activeModels: {
          ...state.activeModels,
          [action.id]: {
            ...state.activeModels[action.id],
            textures: state.activeModels[action.id].textures.filter(
              (texture) => texture !== action.texture
            ),
          },
        },
      };
    }
    case 'SET_TEXTURE_ORDER': {
      return {
        ...state,
        textureOrder: action.data,
      };
    }
    case 'UPLOADING_MODEL': {
      return { ...state, isUploading: !state.isUploading };
    }
    case 'SAVING_MODEL': {
      return { ...state, isSaving: !state.isSaving };
    }
    case 'UPLOADING_TEXTURE': {
      return { ...state, isUploadingTexture: !state.isUploadingTexture };
    }
    case 'DELETING_TEXTURE': {
      return { ...state, isDeletingTexture: !state.isDeletingTexture };
    }
    default:
      return state;
  }
};

export default models;

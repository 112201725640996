import { omit } from 'lodash';

const initialState = {
  uuids: {},
};

const three = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SAVE_UUID': {
      return {
        ...state,
        uuids: { ...state.uuids, [action.id]: action.uuid },
      };
    }
    case 'DELETE_UUID': {
      return {
        ...state,
        uuids: omit(state.uuids, action.id),
      };
    }
    default:
      return state;
  }
};

export default three;

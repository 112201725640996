import { combineReducers } from 'redux';
import config from './config';
import settings from './settings';
import models from './models';
import three from './three';
import places from './places';
import lights from './lights';
import overlays from './overlays';
import error from './error';

const createReducer = combineReducers({
  config,
  models,
  settings,
  three,
  places,
  lights,
  overlays,
  error,
});

export default createReducer;

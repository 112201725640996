const initialState = {
  isSaving: false,
};
// to be expanded
const settings = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SAVE_SETTINGS': {
      return {
        ...state,
        ...action.data,
      };
    }
    case 'TOGGLE_SAVING_SETTINGS': {
      return {
        ...state,
        isSaving: !state.isSaving,
      };
    }
    default:
      return state;
  }
};

export default settings;

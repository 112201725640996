import { useEffect } from 'react';

import { getMap } from '../../interface/';

const useMapMouseClick = ({ action }) => {
  useEffect(() => {
    const map = getMap();
    const actionReady = typeof action === 'function';
    map && actionReady && map.on('click', action);
    return () => {
      map && actionReady && map.off('click', action);
    };
  }, [action]);
};

export default useMapMouseClick;

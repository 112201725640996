import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Button } from '../../common/buttons';
import Icon from '../../common/layout/Icon';

const SignOut = () => {
  const { keycloak } = useKeycloak();
  return (
    <Button className="hover-disabled logout" onClick={() => keycloak.logout()}>
      <Icon iconName="exit_to_app" />
      &nbsp;Sign Out
    </Button>
  );
};

export default SignOut;

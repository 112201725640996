import React from 'react';
import ReactDOM from 'react-dom';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { store } from './store';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import CacheBuster from 'components/CacheBuster/CacheBuster';

import './styles/index.scss';

export const keycloak = new Keycloak('/keycloak.json');

const history = createBrowserHistory();

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ onLoad: 'check-sso', checkLoginIframe: false }}
  >
    <HashRouter history={history}>
      <Provider store={store}>
        <App />
        <CacheBuster />
      </Provider>
    </HashRouter>
  </ReactKeycloakProvider>,
  document.getElementById('root')
);

serviceWorker.register();

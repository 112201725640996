import api from '../api/api';

/**
 * Fetches all locations
 * @param  {number} mapId - id from search params
 */
export const fetchOverlays = (mapId) => async (dispatch, getState) => {
  try {
    const { data } = await api.getOverlays(mapId);
    dispatch(saveOverlays(data));
  } catch (error) {
    console.log(error);
  }
};

export const saveOverlays = (data) => ({
  type: 'SAVE_OVERLAYS',
  data,
});

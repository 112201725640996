import * as THREE from 'three';

const lights = new THREE.Group();

export function initLights(scene) {
  scene.add(lights);
}

export function getLights() {
  return lights;
}

/**
 * @returns the default light
 */
function createDefaultLight() {
  return new THREE.HemisphereLight(0x525252, 0xc0c0c0, 1.5);
}

/**
 * @returns a light
 * @param  {String} light
 */
export function createLight(light) {
  switch (light.light_type) {
    case 'hemisphere': {
      return new THREE.HemisphereLight(
        0x525252,
        0xc0c0c0,
        light.light_intensity
      );
    }
    case 'directional': {
      return new THREE.DirectionalLight(0xc0c0c0, light.light_intensity);
    }
    case 'ambient': {
      return new THREE.AmbientLight(0xc0c0c0, light.light_intensity);
    }
    default: {
      return createDefaultLight();
    }
  }
}

/**
 * Removes a light from the lights group
 * @param  {UUID} id
 */
export function deleteLight(id) {
  const light = lights.children.find((light) => light.userData.id === id);
  lights.remove(light);
}

/**
 * Adds default light to the lights group
 */
export function addDefaultLights() {
  lights.add(createDefaultLight());
}

/**
 * Adds a light to the lights group
 */
export function addLight(light) {
  lights.add(createLight(light));
}

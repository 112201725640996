import React from 'react';
import { Oval } from 'react-loader-spinner';

import './AuthPage.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const AuthPage = () => (
  <div className="auth-page">
    <div>
      <Oval type="Oval" color="green" height={80} width={80} />
      <div>Authorizing</div>
    </div>
  </div>
);

export default AuthPage;

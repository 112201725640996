import * as THREE from 'three';
import { getCamera } from './scene';
import { getMap } from './map';
import { DEFAULT_HOVER_COLOR } from './constants';

const raycaster = new THREE.Raycaster();
const highlighted = [];

/**
 * @returns an object on mouse intersect
 * @param  {Object} point
 * @param  {Object} group
 */
export function raycast(point, group) {
  const map = getMap();
  const mouse = new THREE.Vector2();
  mouse.x = (point.x / map.transform.width) * 2 - 1;
  mouse.y = 1 - (point.y / map.transform.height) * 2;
  raycaster.setFromCamera(mouse, getCamera());
  return raycaster.intersectObjects(group, true)[0];
}

/**
 * Runs highlight() on mouse intersect
 * @param  {event} e
 * @param  {Object} bldgs
 * @param {Object} history
 */
export function onClick(e, bldgs, history) {
  const intersect = raycast(e.point, bldgs);
  if (intersect) {
    const { object } = intersect;
    if (object.userData.id) {
      history.push({
        pathname: `/place/${object.userData.id}`,
      });
    }
  }
}

/**
 * Runs highlight() on mouse intersect
 * @param  {event} e
 * @param  {Object} group
 */
export function hover(e, group) {
  highlighted.forEach((h) => {
    if (h.userData.originalColor) {
      h.material.color.set(h.userData.originalColor);
    }
  });
  highlighted.length = 0;
  const intersect = raycast(e.point, group);
  if (intersect) {
    const { object } = intersect;
    highlightModel(object, object.userData.hover_color, true);
  }
}

/**
 * Highlights a model
 * @param  {object} model - threejs Object3D
 * @param  {string} color - color to highlight the model with
 * @param  {bool} isHovered - adds hovered models to highlighted array
 */
export function highlightModel(model, color, isHovered = false) {
  if (model instanceof THREE.Object3D) {
    const threeColor = getColor(color || DEFAULT_HOVER_COLOR);
    model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.userData.originalColor = child.material.color.getHex();
        child.material.color.set(threeColor);
        if (isHovered) {
          highlighted.push(child);
        }
      }
    });
  }
}

/**
 * sets a models hover higlihgt color
 * @param  {object} model - threejs Object3D
 * @param  {string} color - color to set the models hover color with
 */
export function setModelHightlightColor(model, color) {
  if (model instanceof THREE.Object3D) {
    const threeColor = getColor(color || DEFAULT_HOVER_COLOR);
    model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.userData.hover_color = threeColor;
      }
    });
  }
}

/**
 * @returns a threejs color
 * @param  {string} color
 */
export function getColor(color) {
  return new THREE.Color(color);
}

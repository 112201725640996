import { omit } from 'lodash';

const initialState = {
  isSaving: false,
  atlasLocations: [],
  places: {},
  modelData: {},
};

const places = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'PLACE_SAVING': {
      return {
        ...state,
        isSaving: !state.isSaving,
      };
    }
    case 'SAVE_PLACE': {
      const placeIds =
        state.modelData[action.data.model_id] &&
        state.modelData[action.data.model_id].placeIds;
      const iterablePlaceIds = placeIds || [];
      return {
        ...state,
        places: {
          ...state.places,
          [action.data.id]: {
            ...state.places[action.data.id],
            ...action.data,
          },
        },
        modelData: {
          ...state.modelData,
          [action.data.model_id]: {
            placeIds: [...iterablePlaceIds, action.data.id],
            id: action.data.model_id,
            reusable: action.data.reusable,
            fileName: action.data.name,
            textures: action.data.textures || {},
          },
        },
      };
    }
    case 'DELETE_PLACE': {
      return {
        ...state,
        places: omit(state.places, action.id),
        modelData: {
          ...state.modelData,
          [action.modelId]: {
            ...state.modelData[action.modelId],
            placeIds: state.modelData[action.modelId].placeIds.filter(
              (id) => id !== action.id
            ),
          },
        },
      };
    }
    case 'SAVE_ATLAS_LOCATIONS': {
      return {
        ...state,
        atlasLocations: action.data,
      };
    }
    default:
      return state;
  }
};

export default places;

import api from '../api/api';

/**
 * fetches 360 map settings
 */
export const fetchSettings = () => async (dispatch, getState) => {
  try {
    const { map, name } = getState().config;
    const { data } = await api.getSettings(map);
    // remove this once atlas cms creates this data
    if (data === null) {
      dispatch(
        createSettings({
          map,
          name,
          active: 1,
          minzoom: 17,
        })
      );
    }

    const lodParsed = JSON.parse(data.lod);
    const dvParsed = JSON.parse(data.default_view);
    // look into how data is init saved. a double parse was needed on init data
    const lod = lodParsed === 'string' ? JSON.parse(lodParsed) : lodParsed;
    const default_view =
      dvParsed === 'string' ? JSON.parse(dvParsed) : dvParsed;
    dispatch(saveSettings({ ...data, lod, default_view }));
  } catch (error) {}
};

/**
 * creates 360 map settings
 */
export const createSettings = (settings) => async (dispatch, getState) => {
  try {
    const { map } = getState().config;
    const { status } = await api.createSettings(map, settings);
    status === 200 && dispatch(saveSettings(settings));
  } catch (error) {
    console.log(error.message);
  }
};

/**
 * updates 360 map settings
 */
export const updateSettings = (settings) => async (dispatch, getState) => {
  dispatch(toggleSaving());
  try {
    const { map } = getState().config;
    const { status } = await api.updateSettings(map, settings);
    status === 200 && dispatch(saveSettings(settings));
    dispatch(toggleSaving());
  } catch (error) {
    console.log(error);
    dispatch(toggleSaving());
  }
};

/**
 * saves settings
 * @param  {object} data - config data
 */
export const saveSettings = (data) => ({
  type: 'SAVE_SETTINGS',
  data,
});

/**
 * toggles isSaving
 */
export const toggleSaving = () => ({
  type: 'TOGGLE_SAVING_SETTINGS',
});

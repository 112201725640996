import * as THREE from 'three';
import { addBuilding } from '.';

export function addDrawCall() {
  const material = new THREE.LineBasicMaterial({
    color: 0x0000ff,
  });

  const points = [];
  points.push(new THREE.Vector3(0, 0, 0));

  const geometry = new THREE.BufferGeometry().setFromPoints(points);

  const line = new THREE.Line(geometry, material);
  line.frustumCulled = false;
  addBuilding(line);
}

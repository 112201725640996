import React from 'react';
import classnames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import UserMenu from './UserMenu/UserMenu';
import { Link, Button } from '../common/buttons';
import Icon from '../common/layout/Icon';
import logoDark from '../../assets/logo-icon.svg';
import './header.scss';

const Navbar = () => {
  const location = useLocation();
  const history = useHistory();
  const pushHistory = (pathname) => {
    history.push(`/${pathname}`);
  };
  const hidden = location.pathname === '/notfound';
  const headerClasses = classnames('header', { hidden });
  return (
    <header className={headerClasses}>
      <nav className="header__nav-btns">
        <Link className="c3d-btn" href="https://www.concept3d.com/" newTab>
          <img
            src={logoDark}
            height="28"
            alt="Concept3D Logo"
            className="app-portal-btn"
          />
        </Link>
        <Button
          className="header__home-btn"
          handleClick={() => history.push('/')}
          role="link"
          data-cy="header__home-btn"
        >
          <Icon iconName="home" />
          &nbsp;360° Maps
        </Button>
        <Button
          className="header__link-btn"
          onClick={() => pushHistory('places')}
          role="link"
          data-cy="header__places-btn"
        >
          <Icon iconName="place" />
          &nbsp;Places
        </Button>
        <Button
          className="header__link-btn"
          onClick={() => pushHistory('models')}
          role="link"
          data-cy="header__model-library-btn"
        >
          <Icon iconName="account_balance" />
          &nbsp;Model Library
        </Button>
        <Button
          className="header__link-btn"
          onClick={() => pushHistory('settings')}
          role="link"
          data-cy="header__settings-btn"
        >
          <Icon iconName="settings" />
          &nbsp;Map Settings
        </Button>
      </nav>
      <UserMenu />
    </header>
  );
};

export default Navbar;

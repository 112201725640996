import { useEffect } from 'react';
import axios from 'axios';

const useInterceptor = (token) => {
  useEffect(() => {
    const interceptor = axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (err) => Promise.reject(err)
    );
    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, [token]);
};

export default useInterceptor;
